function ShrinkIconMouse() {
	function onTouchStart() {
		document.body.classList.add('user-is-touching');
	}
	function onTouchEnd() {
		document.body.classList.remove('user-is-touching');
	}

	window.addEventListener('touchstart', onTouchStart);
	window.addEventListener('touchend', onTouchEnd);

	return () => {
		window.removeEventListener('touchstart', onTouchStart);
		window.removeEventListener('touchend', onTouchEnd);
	};
}

export default ShrinkIconMouse;
