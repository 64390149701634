import FontFaceObserver from 'fontfaceobserver';

export default () => {
	const $html = document.getElementsByTagName('html')[0];
	const arial = new FontFaceObserver('ArialRoundedMT');
	const body = new FontFaceObserver('HelveticaNeueLTStd-Lt');

	Promise.all([arial.load(), body.load()])
		.then(() => $html.classList.add('fonts-loaded'))
		.catch(() => $html.classList.add('fonts-failed'));
};
