import { withPlugins, domEvents } from '@spon/plugins';

function SplashAnimation({ plugins: { addEvents } }) {
	const splashScreen = document.querySelector('[data-splash-screen]');
	// const splashScreen = false;

	if (splashScreen) {
		setTimeout(() => {
			splashScreen.classList.remove('c-splash--visible');
		}, 4000);

		setTimeout(() => {
			splashScreen.classList.add('hidden');

			document.body.classList.remove('hidden-content');
			document.documentElement.classList.remove('show-splash');
		}, 6000);
	} else {
		document.body.classList.remove('hidden-content');
		document.documentElement.classList.remove('show-splash');
	}

	function triggerAnimation(e) {
		e.preventDefault();

		if (splashScreen) {
			splashScreen.classList.remove('c-splash--visible');
		}

		setTimeout(() => {
			if (splashScreen) {
				splashScreen.classList.add('hidden');
			}
			document.body.classList.remove('hidden-content');
			document.documentElement.classList.remove('show-splash');
		}, 2000);
	}

	addEvents({
		'click [data-splash-screen]': triggerAnimation,
	});
}

export default withPlugins(domEvents)(SplashAnimation);
