import '/src/scss/style.scss';

if (import.meta.hot) {
	import.meta.hot.accept();
}

import { loadApp, loadModule } from '@spon/core';
import 'lazysizes';

import '@/plugins/logger';
import webfontloader from '@/plugins/webfontloader';
import detectTab from '@/utils/detectTab';

import SetVh from '@/global/SetVh';
import ShrinkIconMouse from '@/global/ShrinkIconMouse';

import activeLinks from '@/utils/activeLinks';
import localLinks from '@/utils/localLinks';

import SplashAnimation from '@/global/SplashAnimation';

import barba from '@barba/core';
import barbaCss from '@barba/css';

webfontloader();
detectTab();
activeLinks(window.location.pathname);
localLinks();

let app = loadApp(name => import(`./behaviours/${name}.js`), document.body); // eslint-disable-line

loadModule({
	module: SetVh,
	id: 'set-vh',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: ShrinkIconMouse,
	id: 'body',
	node: document.body,
	keepAlive: true,
});

loadModule({
	module: SplashAnimation,
	id: 'body',
	node: document.body,
	keepAlive: true,
});

const link = document.getElementById('header-link');

if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual';
}

// Barba
barba.use(barbaCss);

barba.init({
	// prefetchIgnore: true,
	debug: true,
	// timeout: 5000,
});

// 6
barba.hooks.leave(({ current: { container, namespace } }) => {
	container.style.transition = 'opacity 700ms';
	container.style.opacity = 0;
	// Only set scrollY if we're going away from Home

	if (namespace === 'homepage') {
		document.body.dataset.scroll = window.scrollY;
	}
});

// 7
barba.hooks.afterLeave(() => {
	app.destroy();
});

// 9
barba.hooks.enter(({ next: { container } }) => {
	container.style.transition = 'none';
	container.style.opacity = 0;
	app.destroy();

	app = loadApp(name => import(`./behaviours/${name}.js`), container); // eslint-disable-line
});

// 10
barba.hooks.afterEnter(({ next: { container, namespace } }) => {
	let y = 0;

	if (namespace === 'homepage') {
		document.body.classList.remove('fake-overlay');
		link.title = 'Contact';
		link.href = 'contact';
		y = document.body.dataset.scroll || 0;
	} else {
		document.body.classList.add('fake-overlay');
		link.title = 'Homepage';
		link.href = '/';
	}

	// 	app = loadApp(name => import(`./behaviours/${name}.js`), container); // eslint-disable-line
	setTimeout(() => {
		window.scroll(0, y);
		container.style.transition = 'opacity 700ms';
		container.style.opacity = 1;
	}, 200);
});
